<template>
  <CustomDialog
    :title="
      isEdit
        ? 'Edit Category'
        : type == 'sub'
        ? 'New Sub-category'
        : 'New Category'
    "
    v-model="open"
    @button2="save"
    :main-btn-disabled="!valid"
    :button2-text="isEdit ? 'Update' : 'Save'"
  >
    <template v-slot:content>
      <v-form ref="form" v-model="valid">
        <v-row dense>
          <v-col cols="12" v-if="type == 'sub'">
            <label class="subtitle-2 primary--text">Main Category</label>
            <v-text-field
              hide-details="auto"
              outlined
              dense
              readonly
              disabled
              :value="main.title"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <label class="subtitle-2 primary--text">
              {{ type == 'sub' ? 'Sub-category' : 'Category' }} Title
            </label>
            <v-text-field
              autofocus
              clearable
              clear-icon="mdi-close-circle-outline"
              hide-details="auto"
              outlined
              :rules="[requiredRule()]"
              dense
              v-model="title"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
export default {
  components: { CustomDialog },
  props: {
    categories: { type: Array },
    value: { type: Boolean, default: false },
    isEdit: { type: Boolean, default: false },
    type: { type: String, default: 'main' },
    item: { type: Object },
    main: { type: Object }
  },
  data: () => ({
    valid: false,
    open: false,
    title: null
  }),
  watch: {
    value(val) {
      this.open = val
      if (val && !this.isEdit) {
        this.title = null
      }
    },
    open(val) {
      this.$emit('input', val)
    },
    item: {
      handler: function (val) {
        this.title = val ? val.title : null
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    payload() {
      return {
        id: this.isEdit && this.item ? this.item.id : null,
        main: this.type === 'sub' && this.main ? this.main.id : null,
        title: this.title
      }
    }
  },
  methods: {
    save() {
      this.$emit(this.isEdit ? 'update' : 'save', this.payload)
    }
  }
}
</script>

<style></style>
