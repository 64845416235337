<template>
  <v-card min-height="100vh" outlined flat class="rounded-10 overflow-hidden">
    <v-card-title style="min-height: 100px" class="primary d-block rounded-0">
      <v-row dense align="center" justify="space-between">
        <v-breadcrumbs light :items="paths">
          <template v-slot:divider>
            <v-icon color="white">mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              @click="$router.push({ path: item.href })"
              :disabled="item.disabled"
            >
              <span class="white--text cursor-pointer">{{ item.text }}</span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-btn
          @click="$router.go(-1)"
          small
          depressed
          class="text-none primary--text"
          color="white"
        >
          <v-icon color="primary" left>mdi-chevron-left</v-icon> Back
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-actions class="d-flex align-center justify-center">
      <v-btn
        depressed
        @click="addCategory"
        class="text-none px-5 ma-2"
        color="primary"
      >
        Add new category
      </v-btn>
      <v-btn
        :disabled="!hasChanges || saving"
        depressed
        :loading="saving"
        @click="saveChanges"
        class="text-none px-5 ma-2"
        color="primary"
      >
        Save Changes
      </v-btn>
    </v-card-actions>
    <v-card-text class="py-4">
      <PageLoader min-height="100vh" v-if="loading"></PageLoader>
      <v-sheet>
        <v-row
          v-for="(main, index) in categories"
          :key="main.id"
          dense
          align="start"
          justify="start"
        >
          <v-col
            cols="12"
            md="12"
            sm="12"
            class="d-flex align-start justify-start flex-column"
          >
            <div class="d-flex align-center justify-center">
              <v-checkbox
                :id="`checkbox-${index}`"
                :value="main.id"
                v-model="main_selected"
                hide-details="auto"
                color="primary accent-4"
              ></v-checkbox>
              <label
                :for="`checkbox-${index}`"
                class="primary--text mt-4 fw-700 cursor-pointer"
              >
                {{ main.title | ucwords }}
              </label>
              <HelpToolTip
                icon-class="mt-4 mx-1"
                icon-size="small"
                icon="mdi-pencil"
                text="Edit category"
                @click:icon="editCategory(main)"
              ></HelpToolTip>
            </div>
            <div class="d-flex fullwidth my-2">
              <v-row dense>
                <v-col
                  cols="12"
                  md="3"
                  grow
                  sm="6"
                  v-for="sub in main.subcategories"
                  :key="sub.id"
                >
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :disabled="mainIsNotSelected(main)"
                      class="rounded-5 d-flex fill-height"
                      min-height="60"
                      dense
                      outlined
                      flat
                    >
                      <v-expand-transition>
                        <v-card
                          v-if="hover"
                          class="d-flex align-center justify-center transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%; width: 100%"
                        >
                          <v-btn
                            small
                            @click.stop="deleteSubcategory(main, sub)"
                            depressed
                            class="text-none ma-1"
                            color="primary"
                          >
                            Delete
                          </v-btn>
                          <v-btn
                            small
                            @click.stop="editSubcategory(main, sub)"
                            depressed
                            class="text-none ma-1"
                            color="primary"
                          >
                            Edit
                          </v-btn>
                        </v-card>
                      </v-expand-transition>
                      <v-card-text class="text-center secondary--text fw-700">
                        {{ sub.title }}
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                  <v-card
                    class="rounded-5 fw-900 primary--text fill-height d-flex align-center justify-center"
                    min-height="60"
                    dense
                    @click.stop="addSubcategory(main)"
                    outlined
                    flat
                  >
                    <v-icon color="primary" left>mdi-plus</v-icon> Add New
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-card-text>
    <v-card-actions class="d-flex align-center justify-center">
      <v-btn
        depressed
        @click="addCategory"
        class="text-none px-5 ma-2"
        color="primary"
      >
        Add new category
      </v-btn>
      <v-btn
        :disabled="!hasChanges || saving"
        depressed
        :loading="saving"
        @click="saveChanges"
        class="text-none px-5 ma-2"
        color="primary"
      >
        Save Changes
      </v-btn>
    </v-card-actions>
    <CategoryDialog
      :categories="categories"
      :is-edit="isEdit"
      :type="category_type"
      :item="category_selected"
      :main="category_main"
      v-model="category_dialog"
      @update="handleUpdate"
      @save="handleSave"
    ></CategoryDialog>
  </v-card>
</template>

<script>
import _ from 'lodash'
import request from '@/services/axios_instance'
import CategoryDialog from './components/CategoryDialog.vue'
export default {
  components: { CategoryDialog },
  data: () => ({
    saving: false,
    categories: null,
    loading: false,
    main_selected: [],
    original_selected: [],
    category_dialog: false,
    category_selected: null,
    category_main: null,
    category_type: 'main',
    isEdit: false
  }),
  created() {
    this.fetchCategories()
  },
  computed: {
    paths() {
      let x = [
        {
          text: 'Courses',
          disabled: false,
          href: '/dashboard/courses'
        },
        {
          text: 'Categories',
          disabled: true,
          href: null
        }
      ]
      return x
    },
    hasChanges() {
      return _.cloneDeep(this.original_selected).sort().join(',') ===
        _.cloneDeep(this.main_selected).sort().join(',')
        ? false
        : true
    }
  },
  methods: {
    fetchCategories() {
      this.loading = true
      request
        .get(`api/courses/categories/all`)
        .then(({ data }) => {
          this.categories = data
          this.original_selected = this.main_selected = data
            .filter((j) => j.active)
            .map((i) => i.id)
        })
        .finally(() => (this.loading = false))
    },
    mainIsNotSelected(category) {
      return !this.main_selected.includes(category.id)
    },
    editCategory(main) {
      this.category_main = null
      this.category_selected = main
      this.category_dialog = true
      this.category_type = 'main'
      this.isEdit = true
    },
    editSubcategory(main, sub) {
      this.category_main = main
      this.category_selected = sub
      this.category_dialog = true
      this.category_type = 'sub'
      this.isEdit = true
    },
    addCategory() {
      this.category_selected = null
      this.category_main = null
      this.category_dialog = true
      this.category_type = 'main'
      this.isEdit = false
    },
    addSubcategory(mainCategory) {
      this.category_main = mainCategory
      this.category_selected = null
      this.category_dialog = true
      this.category_type = 'sub'
      this.isEdit = false
    },
    deleteSubcategory(main, sub) {
      this.appConfirmation(
        `Delete subcategory? (${sub.title})`,
        () => {
          request.delete(`api/courses/categories/${sub.id}`).then(() => {
            this.fetchCategories()
          })
        },
        null,
        'Yes! Delete'
      )
    },
    handleUpdate(payload) {
      request
        .put(`api/courses/categories/${payload.id}`, payload)
        .then(({ data }) => {
          this.fetchCategories()
          this.category_dialog = false
        })
    },
    handleSave(payload) {
      request.post(`api/courses/categories`, payload).then(({ data }) => {
        this.fetchCategories()
        this.category_dialog = false
      })
    },
    saveChanges() {
      this.saving = true
      request
        .put(`api/courses/categories/active`, { selected: this.main_selected })
        .then(({ data }) => {
          this.categories = data
          this.original_selected = this.main_selected = data
            .filter((j) => j.active)
            .map((i) => i.id)
          this.appSnackbar(`Categories changes saved`)
        })
        .finally(() => (this.saving = false))
    }
  }
}
</script>

<style></style>
